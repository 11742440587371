@font-face {
    font-family: 'Chess Alpha';
    src: url('../assets/fonts/chess/chessalpha-webfont.woff2') format('woff2'), url('../assets/fonts/chess/chessalpha-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.pgn-viewer {
    width: 100%;
    height: 100%;
    font-size: 15px;
    opacity: 0.8;
    overflow: hidden;
    overflow-y: auto;
}

.pgn-viewer__group {
    color: navy;
}

.pgn-viewer__sub-group {
    color: green;
    margin: 5px 0;
}

.pgn-viewer__move {
    font-size: 16px;
}

.pgn-viewer__figure-font {
    font-family: "Chess Alpha" , sans-serif;
}

.pgn-viewer_grey {
    color: #182026;
}

.pgn-viewer_bold {
    font-weight: 500;
}

.pgn-viewer_hover:hover {
    color: white;
    cursor: pointer;
    background: brown;
}

.pgn-viewer_selected {
    color: white;
    background: brown;
}

.pgn-viewer_move-p {
    display: inline-block;
    padding: 3px;
}

.pgn-viewer__comment {
    line-height: 1.6;
}