@import '../../assets/fonts/ico/ico.css';

.btn {
    color: #526579;
    cursor: pointer;
    font-weight: 400;
    position: relative;
    white-space: nowrap;
    display: inline-block;
    text-decoration: none;
    padding: 7px 15px;
    font-size: 20px;
    border-radius: 5px;
    background: #f4f6f8;
    outline: none;
    margin-right: 5px;
    line-height: 0;
    border: 1px solid #f4f6f8;
}

.btn_disabled {
    color: #ccd4dc;
}

.btn:not(.btn_disabled):hover {
    transition: all 0.3s ease-in-out;
    color: #222a32;
    border: 1px solid #c1cbd5;
}
